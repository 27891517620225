import DefaultFormat from './default_format.helper'
import { titleCase, datesAreOnSameDayOrAfter, pickNum, verifyNum } from '../style_string.helper'
import { findDoctor } from '../table.helper'

export default class UroServiceFormat extends DefaultFormat {
  formatDateCSV () {
    let { date } = this.jsonObj
    if (date === undefined) {
      const regex = /\d{4}\/\d{1,2}\/\d{1,2}|\d{1,2}-\d{2}-\d{4}/
      const match = this.optionalParameter.toString().match(regex)
      date = match[0].split(/\D|-\d/)
    } else {
      date = date.split(/\D/)
    }
    let d, m, y
    if (date[0].length === 4) {
      y = date[0]
      m = date[1]
      d = date[2]
    } else {
      d = date[0]
      m = date[1]
      y = date[2]
    }
    // let [y, m, d] = date
    if (d.length === 1 && Number(d) < 10) d = `0${d}`
    if (y.length !== 2) y = y.slice(2)
    date = `${d}/${m}/${y}` // Format date to DD/MM/YY
    if (date.length !== 8) throw new Error(`La fecha de la cita con numero ${this.jsonObj.number} no es válida. El formato debe ser DD/MM/YY.`)

    const today = new Date()
    const dateToCheck = new Date(y, m - 1, d)
    dateToCheck.setFullYear(dateToCheck.getFullYear() + 100) // So that the year is not 1900
    if (isNaN(dateToCheck)) throw new Error(`La fecha de la cita con numero ${this.jsonObj.number} no es válida.`)
    if (!datesAreOnSameDayOrAfter(today, dateToCheck)) throw new Error(`La fecha de la cita con numero ${this.jsonObj.number} no puede ser en el pasado.`)
    return `${d}/${m}/${dateToCheck.getFullYear()}`
  }

  formatDoctorAndSpecialtyCSV () {
    const { doctor: dr } = this.jsonObj
    let doctorTemplate
    const regex = /de (.+?) \(/
    if (this.optionalParameter.includes('Citas para la fecha')) {
      const match = this.optionalParameter.toString().match(regex)
      doctorTemplate = match
        ? match[1].replace('DR', '').replace('.', '').trim()
        : 'no encontrado'
    } else {
      doctorTemplate = dr
    }
    const foundDr = findDoctor(this.doctors, doctorTemplate)
    if (foundDr === undefined) throw new Error(`El usuario ${this.jsonObj.name} tiene como doctor un nombre incorrecto ${doctorTemplate}, por favor, revisa la base de datos.`)
    const doctor = foundDr ? foundDr.firstName + ' ' + foundDr.lastName : ''
    const specialty = foundDr ? foundDr.specialty : ''
    return { doctor, specialty }
  }

  async formatNumberCSV () {
    try {
      const { number1, number2 } = this.jsonObj
      if (number1 === undefined && number2 === undefined) {
        throw new Error(`El usuario ${this.jsonObj.name1} ${this.jsonObj.lastName1} tiene un número inválido.`)
      }
      const n = pickNum(number1, number2)
      return await verifyNum(n, this.jsonObj)
    } catch (error) {
      if (error?.response?.data !== null && parseInt(error?.response?.data.errors[0].code) === 21) {
        throw new Error(`El usuario ${this.jsonObj.name1} ${this.jsonObj.lastName1} tiene un número inválido.`)
      }
      throw new Error(error.message)
    }
  }

  formatNameCSV (name) {
    if (name === undefined) {
      const { name1, name2, lastName1, lastName2 } = this.jsonObj
      name = `${name1} ${name2} ${lastName1} ${lastName2}`
    }
    return titleCase(name)
  }

  formatProcedureCSV (procedure) {
    let p = procedure
    const { observation } = this.jsonObj
    if (observation !== '' && observation !== undefined) {
      p = p.length > 0 ? `${p}, ${observation}` : observation
    }
    return titleCase(p.replace(/(?:\\[rn]|[\r\n])/g, ''))
  }

  formatTimeCSV () {
    let { time } = this.jsonObj
    if (time === '') return ''
    const [t, ap] = time.toUpperCase()
      .trim()
      .replace('�', '')
      .replace('†', '')
      .replace(' ', '')
      .replace('. ', '')
      .replaceAll('.', '') // Remove dots
      .replace(/\s+/g, ' ') // Replace multiple spaces with one
      .replace(/(?:\\[rn]|[\r\n])/g, '') // Remove new lines
      .split(' ')
    const aux = t.length >= 7 ? t.slice(0, -3) : t
    time = `${aux} ${ap}`

    // const ampm = time.split(' ')[1]
    // const hour = time.split(':')[0]
    // if (!procedure.toLowerCase().includes('ecografia') &&
    // ((hour < 7 && ampm === 'AM') ||
    //   // (hour === 12 && ampm === 'PM') ||
    //   (hour > 5 && ampm === 'PM'))) return ''
    return time
  }

  formatLocationCSV () {
    const { location } = this.jsonObj
    let rawLocation = ''
    const locations = {
      clinica_rosario: 'Clínica El Rosario Sede El Tesoro Cra 20 # 2 Sur 185. Piso 10 consultorio 1005.',
      clinica_rosario_v2: 'Clínica El Rosario Sede El Tesoro Cra 20 # 2 Sur 185.',
      city_medica: 'Edificio City Medica Cra. 55A # 35 - 227. Torre 1, piso 2 consultorio 202.',
      ac_quirofanos: 'Clínica AC Quirófanos - Calle 10 Sur # 48 - 7. Cerca a las estación Aguacatala, continuo a los parqueaderos de la Universidad Eafit.',
      q_office: 'Edificio Q Office Calle 2 # 20 - 50. Piso 9 oficina 902.'
    }
    if (this.optionalParameter.includes('Citas para la fecha')) {
      const regex = /\((.*?)\)/
      const match = this.optionalParameter.toString().match(regex)
      rawLocation = match ? match[1] : 'no encontrado'
    }
    if (rawLocation === '') rawLocation = location
    let l = locations.q_office
    if (rawLocation.includes('1005')) l = locations.clinica_rosario
    if (rawLocation.includes('202')) l = locations.city_medica
    if (rawLocation.includes('AC')) l = locations.ac_quirofanos
    return l
  }
}
